@import 'app/../../sass/_variables.scss';

.menuListItem {
  position: relative;

  &.disabled {
    opacity: 0.4;
  }

  .menuIcon {
    z-index: 990;
    filter: invert(11%) sepia(32%) saturate(3217%) hue-rotate(192deg) brightness(90%) contrast(87%);

  }

  &:hover:not(.disabled) {
    .menuIcon {
      filter: invert(60%) sepia(72%) saturate(990%) hue-rotate(160deg) brightness(98%) contrast(97%);
    }
  }

  &::after {
    position: absolute;
    right: 0;
    display: inline-block;
    content: '';
    width: 1px;
    background-color: #eeeeee;
    //border-right: 1px solid #eeeeee;
    height: 28px;
    bottom: 25%;
  }

  &:first-child::before {
    position: absolute;
    left: 0;
    display: inline-block;
    content: '';
    width: 1px;
    background-color: #eeeeee;
    //border-right: 1px solid #eeeeee;
    height: 28px;
    bottom: 25%;
  }
}



@media (max-width: 950px) {

  .buttonText {
    display: none;
  }

}