.dropdownContainer{
  margin-right: -8px;

  &:before {
    position: absolute;
    left: -9px;
    display: inline-block;
    content: '';
    width: 2px;
    border-right: 1px solid #eeeeee;
    height: 20px;
    bottom: 25%;
  }

}