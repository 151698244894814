.containerClasses{
  list-style-type: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  height: 100%;
  margin: 0;
  display: none;

  .crumb{
    text-transform: uppercase;
    font-size: small;
    padding-bottom: 0;
  }

}


@media(min-width: 500px) {
  .containerClasses{
    display: flex;
  }
}