@import "sass/_variables.scss";

//Deze styling is tot een width van 576px
.footer {
  position: relative !important;
  z-index: 10;
  background-color: $kleur-donkersteBlauw;
  width: 100%;
  color: $body-bg;

  .landen {
    button {
      color: white;
    }
    .landItem {
      color: $kleur-donkersteBlauw !important;
    }
  }
  h3 {
    font-size: 18px;
    color: $kleur-cyan;
    display: flex;
    align-content: center;
    margin-top: 0px;
    margin-bottom: 0px;
    img {
      margin-right: 5px;

    }
  }
  h4 {
    font-size: 16px;
    margin-top: 16px;
    margin-bottom: 4px;
    color: white;
    line-height: 20px;
    font-weight: 700;
    &:hover {
      text-decoration: underline;
      color: $kleur-cyan;
    }
  }
  a, a:not(.btn)  { // a:not(.btn) is om andere stijling te overschijven
    color: white;
    text-decoration: none;
    display: block;
    &:hover {
      text-decoration: underline;
      color: $kleur-cyan;
    }
  }
  .disabled {
    opacity: 0.4;

    &:hover {
      text-decoration: none;
      color: white;
    }
  }

  .footerContainer {

    .footerRow {
      display: flex;
      flex-direction: column;
      margin-left: 20px;
      padding-top: 1.5rem;
      padding-bottom: 1rem;

      .footerCol {
        display: flex;
        flex-direction: column;
        padding-bottom: 3rem;
      }
    }

    .footerContactRegel {
      border-top: 1px solid white;
      border-bottom: 1px solid white;
      font-weight: bold;
      line-height: 32px;
      padding: 8px 15px;
      .footerHelpDesk {
        img {
          vertical-align: middle;
        }
      }
      .socialmediaIcons  {
        text-align: right;
        a {
          display: inline-block;
        }

      }
    }

    .footerDisclaimer {
      display: flex;
      flex-direction: row;
      font-size: 0.6em;
      padding: 8px 15px;
      gap: 7%;
      .footerDisclaimerText {
        width: 85%;
      }

      .footerVersieNumText {
        width: 8%;
        text-align: right;
      }
    }
  }
}

//Deze styling is vanaf een width van 576px
@media screen and (min-width: 576px) {
  .footer {
    .footerContainer {
      .footerContactRegel {
        display: flex;
        flex-direction: row;
        .footerHelpDesk {
          display: flex;
          flex-direction: row;
          width: 70%;

          img {
            width: 24px !important;
            height: 24px;
          }
        }
        .socialmediaIcons  {
          width: 30%;
        }
      }

      .footerDisclaimer {
        .footerDisclaimerText {
          width: 80%;
        }
        .footerVersieNumText {
          width: 12%;
        }
      }
    }
  }
}

// Deze styling is vanaf een width van 768px
@media screen and (min-width: 768px) {
  .footer {
    .footerContainer {
      margin-left: auto;
      margin-right: auto;
      max-width: 720px;
    }
  }
}

//Deze styling is vanaf een width van 992px
@media screen and (min-width: 992px) {
  .footer {
    .footerContainer {
      max-width: 960px;
    }
  }
}

//Deze styling is vanaf een width van 1200px
@media screen and (min-width: 1200px) {
  .footer {
    .footerContainer {
      max-width: 1140px;
      .footerRow {
        display: flex;
        flex-direction: row;
        width: 100%;

          .footerCol {
            width: 33%;
          }

      }
    }
  }
}