@import 'app/../../sass/_variables.scss';

@-webkit-keyframes logoPlaySmall_hover {
  100% {
    background-position: 0 -598px;
  }
}

@keyframes logoPlaySmall_hover {
  100% {
    background-position: 0 -598px;
  }
}

@-webkit-keyframes logoPlaySmall {
  100% {
    background-position: 0 -598px;
  }
}

@keyframes logoPlaySmall {
  100% {
    background-position: 0 -598px;
  }
}


.uitgelogdMenuMijnMkg {
  width: 100%;
  height: 40px;
  border-bottom: 1px solid #eee;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-right: 10px;

  .linkItem {
    font-size: small;
    text-transform: uppercase;
    text-decoration: none;
  }

  .mkgLogo {
    cursor: pointer;

    &:before {
      float: left;
      content: '';
      height: calcRem(40px);
      width: calcRem(52px);
      background: url("/media/logo_spite.png") top center;
      background-size: 100%;
      cursor: pointer;
      //-webkit-animation: logoPlaySmall 1.4s steps(15) forwards; // werkt niet bij onload
      //animation: logoPlaySmall 1.4s steps(15) forwards;
    }

    &:hover > .mkgLogoTekst {
      color: $kleur-cyan;
    }

    &:hover:before {
      -webkit-animation: logoPlaySmall_hover 1.4s steps(15) forwards;
      animation: logoPlaySmall_hover 1.4s steps(15) forwards;

    }

    &:after {
      clear: both;
    }

    .mkgLogoTekst {
      line-height: 16px;
      padding-top: 7px;
      float: right;
    }
  }

  .menuRight {
    margin-left: auto;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
  }


}


.menuMkgEu {
  background-color: rgba(192, 192, 192, 0.4);
  position: fixed !important;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  font-size: 0.875rem;

  a {
    color: #FFFFFF;
  }


  .containScroll {
    transition: 300ms;
    background-color: rgb(255, 255, 255);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);

    .container{
      .menuLinks{
        a{

          color: #111b28;
          &:hover {
            color: $kleur-cyan;
          }
        }
      }

      .mkgLogo{
        .mkgLogoTekst{
          color: #111b28;
          &:hover {
            color: $kleur-cyan;
          }

        }

      }
    }
  }


  .container {
    margin: 0 auto;
    width: 1150px;
    height: 76px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    //text-transform: uppercase;
    white-space: nowrap;

    @media (max-width: 1150px) {
      width: 100%;
      .menuLinks {
        display: none !important;
      }
      .hamburger {
        padding-right: 30px !important;
      }
    }


    .mkgLogo {
      cursor: pointer;

      &:before {
        float: left;
        content: '';
        height: calcRem(40px);
        width: calcRem(52px);
        margin: 7px 0;
        background: url("/media/logo_spite.png") top center;
        background-size: 100%;
        cursor: pointer;
        //-webkit-animation: logoPlaySmall 1.4s steps(15) forwards; // werkt niet bij onload
        //animation: logoPlaySmall 1.4s steps(15) forwards;
      }

      &:hover > .mkgLogoTekst {
        color: $kleur-cyan;
      }

      &:hover:before {
        -webkit-animation: logoPlaySmall_hover 1.4s steps(15) forwards;
        animation: logoPlaySmall_hover 1.4s steps(15) forwards;

      }

      &:after {
        clear: both;
      }

      .mkgLogoTekst {
        line-height: 16px;
        padding-top: 7px;
        float: right;
      }
    }

    .menuLinks {
      display: flex;
      flex-grow: 2;
      flex-wrap: nowrap;
      justify-content: flex-end;
      align-items: center;
      text-transform: uppercase;
      white-space: nowrap;

      & > * {
        padding: 0 20px;
        text-decoration: none;

        &:last-child {
          padding-right: 80px;
        }
      }
    }

    .hamburger {
      font-size: 24px;
      float: right;
      padding-bottom: 10px;
    }

  }

}

.menuMkgEUFull {
  transition: 500ms ease-in-out;
  background-color: white;
  -webkit-box-shadow: 0px 3px 10px -5px rgba(21, 44, 73, 0.4);
  -moz-box-shadow: 0px 3px 10px -5px rgba(21, 44, 73, 0.4);
  box-shadow: 0px 3px 10px -5px rgba(21, 44, 73, 0.4);

  .container {
    color: $kleur-donkerBlauw;

    .mkgLogo {
      color: $kleur-donkerBlauw;

      &:hover {
        .mkgLogoTekst {
          transition: 150ms ease-out;
          color: $kleur-cyan;
        }
      }
    }

    .menuLinks {
      .ctmRouterLink {
        color: $kleur-donkerBlauw;

        &:hover {
          text-decoration: underline;
          transition: 150ms ease-out;
          color: $kleur-cyan;
        }
      }

      a {
        color: $kleur-donkerBlauw;

        &:hover {
          transition: 150ms ease-out;
          color: $kleur-cyan;
        }
      }
    }

    .hamburger {
      svg {
        fill: $kleur-donkerBlauw;

        &:hover {
          transition: 150ms ease-out;
          fill: $kleur-cyan;
        }
      }
    }
  }

}


.menu {
  background-color: white !important;
  color: $kleur-donkerBlauw !important;
  border-bottom: 1px solid #eeeeee;


  .iconContainer {
    font-size: 1rem;
  }

  .link {
    color: $kleur-donkerBlauw !important;
  }

  .logo {
    margin-right: 0.6rem;
  }

  .breadcrumbs {
    padding: 0.8rem 0 0 0 !important;
    line-height: 1.625rem;
  }

  .link, .item {
    text-decoration: none !important;
  }

  .item:not(.dropdown ) {
    padding: 0.8rem 1rem 0 1rem;
    color: #111b28;
    line-height: 1.625rem;
  }

  .dropdown {
    color: $kleur-donkerBlauw !important;
    padding: 0;

    & > a {
      color: $kleur-donkerBlauw !important;
      text-decoration: none !important;
    }

    & > div {
      min-width: 300px
    }

  }
}


.submenuItemBefore {
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  content: '';
  width: 20px;
  height: 20px;
  margin-right: 4px;
  margin-bottom: -6px;
  filter: invert(11%) sepia(32%) saturate(3217%) hue-rotate(192deg) brightness(90%) contrast(87%);
}


.submenu {
  //margin-top: 4px;
  //display: flex;
  //flex-direction: row;
  //flex-wrap: nowrap;
  //justify-content: center;
  font-weight: bold;
  color: $kleur-donkersteBlauw;
  width: 1110px;

  list-style-type: none;
  padding: 0;
  margin: 0;
  overflow: hidden;

  display: table;
  table-layout: fixed;

  li {
    display: table-cell;
    //float: left;
    //width: 185px;
    text-align: center;
    position: relative;

    &:nth-child(1) {
      width: 56px;
    }

    &::after {
      position: absolute;
      right: 0;
      display: inline-block;
      content: '';
      width: 2px;
      border-right: 1px solid #eeeeee;
      height: 28px;
      bottom: 25%;
    }

    &:first-child::before {
      position: absolute;
      left: 0;
      display: inline-block;
      content: '';
      width: 2px;
      border-right: 1px solid #eeeeee;
      height: 28px;
      bottom: 25%;
    }
  }


  .submenuItem {
    padding: 18px 20px;
    position: relative;
    text-align: center;
    display: inline-block;
    text-decoration: none;
    color: $kleur-donkersteBlauw;
    width: calc(100% - 2px); // ivm de border
    &.disabled {
      opacity: 0.4;
    }

    &:hover:not(.disabled):not(.active) {
      cursor: pointer;
      color: $kleur-cyan;

      &::before {
        filter: invert(60%) sepia(72%) saturate(990%) hue-rotate(160deg) brightness(98%) contrast(97%);
      }
    }

    &.active {
      background-color: $kleur-cyan;
      color: white;
      border-top: 2px solid $kleur-donkerBlauw;

      &::before {
        filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(108deg) brightness(104%) contrast(104%);
      }
    }

    &.dashboard {
      &::before {
        margin-left: -3px;
        @extend .submenuItemBefore;
        background-image: url("/media/Dashboard/icons/home.svg");
      }

    }

    &.mijnGegevens::before {
      @extend .submenuItemBefore;
      background-image: url("/media/Hoofdmenu/mijn-gegevens.svg");
    }

    &.bedrijfsdoelen::before {
      @extend .submenuItemBefore;
      background-image: url("/media/Hoofdmenu/bedrijfsdoelen.svg");
    }

    &.academy::before {
      @extend .submenuItemBefore;
      background-image: url("/media/Hoofdmenu/academy.svg");
    }

    &.activiteiten::before {
      @extend .submenuItemBefore;
      background-image: url("/media/Hoofdmenu/activiteiten.svg");
    }

    &.support::before {
      @extend .submenuItemBefore;
      background-image: url("/media/Hoofdmenu/support.svg");
    }

    &.uren::before {
      @extend .submenuItemBefore;
      background-image: url("/media/person_time_ico.svg");
    }

    &.beheer::before {
      @extend .submenuItemBefore;
      background-image: url("/media/Hoofdmenu/beheer.svg");
    }

  }
}

.ctmRouterLink {
  color: white;
  cursor: pointer;

  &:hover {
    color: $kleur-cyan;
  }
}

.submenu {
  overflow-x: auto;
}

.submenuZoekbalk {
  width: 100%;
  min-height: 4px;
  background-color: $kleur-cyan;
  padding: 30px 0;
}


.fullMenu {
  transition: height 1s ease-in-out;
  color: white;
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  height: 0;
  width: 100%;
  overflow: hidden;
  background-color: rgba(17, 27, 40, 0.92);
  text-transform: none;

  &.show {
    height: 100vh;
  }

  a {
    text-decoration: none;
    color: #ffffff;
    outline: none !important;

  }

  .menuTop {
    padding: 16px 8px;

    .menuClose {
      height: 48px;
      width: 48px;
      background: url("/media/close.png") top center;
      background-size: 100%;
      margin-right: 12px;
      cursor: pointer;
    }
  }

  .menuBox {
    display: table;
    border-collapse: collapse;
    width: 100%;
    height: calc(100% - 96px); // 100 - topmenu

    .menuCenter {
      display: table-row;

      .ulMainNav {
        vertical-align: middle;
        display: table-cell;
        text-align: center;
        line-height: 40px;
        font-size: 2rem;
        padding: 0;

        li:last-child {
          margin-top: 50px;

          .vertalingContainer {
            display: flex;
            justify-content: center;
            align-items: center;

            .itemLangShort {
              display: flex;
              justify-content: center;
              align-items: center;
              gap: 10px;
              cursor: pointer;
              font-size: 20px;

              img {
                height: 20px;
              }

              &:nth-child(2) {
                margin: 0 50px;
              }

              &:hover {
                transition: 200ms;
                transform: scale(1.1);
              }
            }


          }
        }


        li a:hover {
          text-decoration: underline;
          color: $kleur-cyan;
        }

        li .customLink:hover {
          cursor: pointer;
          text-decoration: underline;
          color: $kleur-cyan;
        }

      }
    }

    .menuBottom {
      display: table-row;
      background-color: rgb(17, 27, 40,);
      border-top: 1px solid #838d95;

      .menuFooter {
        width: 320px;
        margin: 0 auto;
        text-align: center;
        font-size: 0.8rem;

        a {
          width: 33%;
        }
      }
    }
  }
}

.padenFunctie {
  font-size: 18px;
  padding-top: 9px;
  margin-right: 10px;
  cursor: pointer;
}

.winkelWagen {
  font-size: 18px;
  padding-top: 9px;
  margin-right: 10px;
  color: #152c49;
  cursor: pointer;

  &:hover {
    color: $kleur-cyan;
  }

  .winkelWagenAantal {
    position: relative;

    .winkelWagenAantalnr {
      position: absolute;
      width: 14px;
      height: 14px;
      border-radius: 50%;
      background-color: #FECB47;
      font-size: 10px;
      font-weight: bold;
      display: flex;
      justify-content: center;
      align-items: center;
      top: -5px;
      left: 10px;
    }
  }
}

.ingelogdMenuIcon {
  position: relative;

  &:before {
    position: absolute;
    left: -9px;
    display: inline-block;
    content: '';
    width: 2px;
    border-right: 1px solid #eeeeee;
    height: 20px;
    bottom: -1px;
  }
}

@media (max-width: 400px) {
  .breadcrumbs {
    font-size: 10px !important;
  }
}