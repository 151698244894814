.searchWrapper{
  width: 100%;
  min-height: 4px;
  background-color:  #23c1f4;
  padding: 30px 0;

  .searchBarContainer{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 5px;
    .searchBarSelect{
      width: 80%;
    }
    .searchBarButton{
      background-color: #FECB47;
      border:none !important;
      width: auto;
      white-space: nowrap;
      height: 38px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 10px ;
      span{
        &:last-child{
          margin-left: auto;
        }
      }
    }
  }
}

@media (max-width: 700px) {
  .searchBarContainer {
    .searchBarSelect {
      width: 70%;
    }
  }
  .searchBarButton {
    gap: 5px !important;
  }
}
@media (max-width: 500px) {
  .searchBarContainer {
    .searchBarSelect {
      width: 60%;
    }
  }
  .searchBarButton {
    gap: 5px !important;
  }
}
@media (max-width: 400px) {
  .searchBarButton {
    font-size: 14px !important;
  }
}