@import "sass/_variables.scss";

.loader {
  z-index: 9999;
  position: fixed;
  height: 4px;
  width: 100%;
  overflow: hidden;
  background-color: #ebebeb;
  display: none;

  &.active{
    display: block;
  }
}


.loader:before {
  display: block;
  position: absolute;
  content: "";
  left: -200px;
  width: 200px;
  height: 4px;
  background-color: $kleur-cyan;
  animation: loading 3s linear infinite;
}

@keyframes loading {
  from {
    left: -200px;
    width: 10%;
  }
  50% {
    width: 30%;
  }
  70% {
    width: 70%;
  }
  80% {
    left: 50%;
  }
  95% {
    left: 120%;
  }
  to {
    left: 100%;
  }
}
