@import 'app/../../sass/_variables.scss';

.hamburgerMenuContainer {

.menuItem{
  text-decoration: none !important;
  text-transform: uppercase;
  font-size: small;

}
  a {
    text-transform: capitalize;
    color: white !important;
    text-decoration: none !important;
  }

  .hamburger {
    color: white;
    font-size: 24px;
    padding-bottom: 10px;
    cursor: pointer !important;

    &:hover {
      color: $kleur-cyan;
    }

  }

  .hamburgerBlue{
    transition: 300ms;
    color: $kleur-donkersteBlauw;
  }

  .overlayMenu {
    transition: 500ms ease-in-out;
    color: white;
    position: absolute;
    width: 100%;
    height: 0;
    background-color: rgba(17, 27, 40, 0.92);
    top: 0;
    left: 0;
    z-index: 99;

    .menuContainer {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      a{
        &:hover{
          color: $kleur-cyan !important;
          text-decoration: underline !important;
        }
      }

      .ulMainNav {
        height: 80%;
        list-style-type: none;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        line-height: 40px;
        font-size: 2rem;
        padding: 0;




        li:last-child {
          margin-top: 50px;

          .vertalingContainer {
            display: flex;
            justify-content: center;
            align-items: center;

            .itemLangShort {
              display: flex;
              justify-content: center;
              align-items: center;
              gap: 10px;
              cursor: pointer;
              font-size: 20px;

              img {
                height: 20px;
              }

              &:nth-child(2) {
                margin: 0 50px;
              }

              &:hover {
                transition: 200ms;
                transform: scale(1.1);
              }
            }


          }
        }
      }


      .menuClose {
        position: absolute;
        right: 0;
        top: 10px;
        height: 48px;
        width: 48px;
        background: url("/media/close.png") top center;
        background-size: 100%;
        margin-right: 12px;
        cursor: pointer;
      }

      .menuBottom{
          width: 100%;
          height: 20%;
          background-color: rgb(17, 27, 40,);
          border-top: 1px solid #838d95;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;



        ul{
          text-align: center;
          margin: 24px;
          width: 100%;
          max-width: 320px;
          list-style-type: none;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }
    }


  }

  .overlayMenuShow {
    transition: 500ms ease-in-out;
    height: 100vh;
  }


}

@media (max-width: 1200px) {
  .hamburger {
    margin-right: 8px;
  }
}