@import "../../../sass/variables";
.customDropdownContainer {
  margin-top: 30px;
  display: inline-flex;
  align-items: center;
  background-color: $kleur-donkersteBlauw; /* Dark background color */
  color: white;
  border-radius: 4px;
  padding: 0 8px;
  position: relative;
  width: fit-content;
}

.globeIcon {
  margin: 0 4px 0 0;
}

.customDropdown {
  appearance: none;
  background: transparent;
  border: none;
  color: white;
  width: 60px;
  cursor: pointer;
  padding: 4px 20px 4px 0; /* Adjust padding for text and arrow */
  font-size: 16px;
  font-family: inherit;
  outline: none; /* Remove default outline */
}

.customDropdownContainer::after {
  content: '\25BC'; /* Unicode character for a downward-pointing arrow */
  color: white;
  position: absolute;
  right: 8px;
  pointer-events: none;
}

.customDropdown option {
  background: $kleur-donkersteBlauw; /* Same as the background of the container */
  color: white;
}

/* Additional styling for dropdown options */
.customDropdown:focus option {
  background: white;
  color: black;
}
