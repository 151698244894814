
.ingelogdeGebruikerContainer {

  &:before {
    position: absolute;
    left: -4px;
    display: inline-block;
    content: '';
    width: 2px;
    border-right: 1px solid #eeeeee;
    height: 20px;
    bottom: 25%;
  }
}


.menuBtn {
  background-color: #FDFDFD !important;
  border: none !important;
  font-size: 12px !important;
}
